import FileView from 'components/UI/FileView';
import { Col, Row } from 'react-bootstrap';
import { getDocmentTypes } from 'utils/helper';
import DocumentInfo from './DocumentInfo';

const Documents = ({ documents }) => {
  return (
    <div className="content">
      <div className="card-modal-body" style={{ color: '#ffffff' }}>
        <div className="information-wrapper">
          {documents?.map((document, index) => (
            // <p key={index}>{document.type}</p>
            <>
              {['nin', 'ip', 'vi', 'dl', 'bvn'].includes(document?.type) && (
                <>
                  <Row className="mb-3 align-items-center">
                    <Col md={7}>
                      <DocumentInfo
                        key={`id-${index}`}
                        title="ID Type"
                        value={getDocmentTypes(document?.type)}
                      />
                    </Col>
                    <Col md={5}>
                      <DocumentInfo
                        key={`id-${index}`}
                        title="ID Number"
                        value={document?.number}
                      />
                    </Col>
                    {document?.url?.url && (
                      <FileView
                        label={'Copy of ' + getDocmentTypes(document?.type)}
                        fileName={document?.url?.name}
                        fileUrl={document?.url?.url}
                        action={'view'}
                      />
                    )}
                  </Row>
                </>
              )}
              {['bnNumber', 'rcNumber', 'tin'].includes(document?.type) && (
                <>
                  <Row className="mb-3 align-items-center">
                    <Col md={7}>
                      <DocumentInfo
                        id={'id-9e3K'}
                        title="Data Name"
                        value={getDocmentTypes(document?.type)}
                      />
                    </Col>
                    <Col md={5}>
                      <DocumentInfo
                        id={'id-h33k'}
                        title="Number"
                        value={document?.number}
                      />
                    </Col>
                    {document?.url?.url && (
                      <FileView
                        label={'Copy of ' + getDocmentTypes(document?.type)}
                        fileName={document?.url?.name}
                        fileUrl={document?.url?.url}
                        action={'view'}
                      />
                    )}
                  </Row>
                </>
              )}
              {[
                'utility-Bill',
                'incorp_C',
                's_Doc',
                'C_of_T',
                'Scum_L_C',
                'cacITForm1',
                'cac',
                'cacBn1',
                'moa',
                'cacForm1',
                'cacForm2',
                'cacForm3',
                'cacStatusReport'
              ].includes(document?.type) && (
                <FileView
                  label={getDocmentTypes(document?.type)}
                  fileName={document?.url?.name}
                  fileUrl={document?.url?.url}
                  action={'view'}
                />
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Documents;
