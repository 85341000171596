import React, { useState } from 'react';
import cs from 'classnames';
import { ReactComponent as LinkArrow } from '../../assets/icons/linkArrow.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as ADD } from '../../assets/icons/addBackground.svg';
import { ReactComponent as Line } from '../../assets/icons/line.svg';
import { Container, Col, Row, Navbar, Accordion } from 'react-bootstrap';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Popover } from 'react-tiny-popover';
import './styles.scss';
import { Breadcrumb } from 'antd';
import FilterModal from '../FilterModal';

const TopBar = ({
  headerText,
  number,
  isRightBar,
  addIcon = true,
  breadcrumbs = [],
  addOnClick = null,
  largeTitle,
  colClass = '',
  filterData,
  inputPlaceholder,
  handleFilterSelect = () => null,
  handleFilterApply = () => null,
  withDate = false,
  withOutSearch = false,
  customButton,
  onclickCustomButton
}) => {
  const [isFilterPopover, setIsFilterPopover] = useState(false);

  const handleToggleFilterPopover = () => setIsFilterPopover(!isFilterPopover);

  return (
    <div className="top-bar">
      <Container>
        <Row>
          <Col xs={12} className={cs('pt-4', { [colClass]: colClass })}>
            <div className='d-flex align-items-center'>
              {breadcrumbs.length > 0 && (
                <Breadcrumb separator={<ArrowRightOutlined />}>
                  {breadcrumbs.map((b, i) => (
                    <Breadcrumb.Item
                      key={i}
                      onClick={b.action}
                      className={breadcrumbs.length - 1 !== i ? 'cursor text-white' : ''}
                    >
                      <span className='text-white'>{b.title}</span>
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              )}
            </div>
            <div className="w-100 d-md-flex align-items-center pb-2 selected-budgets-wrapper">
              <div className="me-auto my-2 my-lg-0">
                <div className="d-flex justify-content-md-center pt-2 text-color">
                  {headerText && (
                    <p
                      className={cs('headerText m-0', { ['large']: largeTitle })}
                      style={{ color: '#ffffff' }}
                    >
                      {headerText}
                    </p>
                  )}
                  {number && <p className="textNumber m-0 ps-2">{number}</p>}
                </div>
              </div>

              <div className="d-flex right-side align-items-center text-color">
                {!isRightBar ? (
                  <>
                    {customButton && (
                      <div className="retry-failed px-2 py-1 rounded-2 border" onClick={onclickCustomButton}>
                        {customButton}
                      </div>
                    )}

                    <Popover
                      isOpen={isFilterPopover}
                      align="end"
                      positions={['bottom', 'left']}
                      content={
                        <FilterModal
                          filterData={filterData}
                          inputPlaceholder={inputPlaceholder}
                          handleToggleFilterPopover={handleToggleFilterPopover}
                          handleFilterSelect={handleFilterSelect}
                          handleFilterApply={handleFilterApply}
                          withDate={withDate}
                          withOutSearch={withOutSearch}
                        />
                      }
                      onClickOutside={handleToggleFilterPopover}
                      containerClassName="dialog-filter-wrapper"
                    >
                      <div
                        className="me-3 d-flex align-items-center cursor"
                        onClick={handleToggleFilterPopover}
                      >
                        <Filter />
                        <Navbar.Text className="ms-2">Filter</Navbar.Text>
                      </div>
                    </Popover>
                    <Line className="me-3" />
                    <div className="me-3 d-flex align-items-center cursor">
                      <LinkArrow />
                      <Navbar.Text className="ms-2">Export</Navbar.Text>
                    </div>
                    {addIcon && (
                      <div className="ms-3 cursor">
                        <ADD onClick={addOnClick} />
                      </div>
                    )}
                  </>
                ) : (
                  isRightBar
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default TopBar;
