import React, { useEffect, useState } from 'react';
import {
  ArrowLeftOutlined,
  ArrowUpOutlined,
  RiseOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import SubHeader from './components/SubHeader';
import LineChartView from './components/LineChartView';
import TotalAmount from './components/TotalAmount';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import { columns, recentTransactionsColumn, tableData, tableData2, transactionsMockData, usersMockData } from 'utils/mockData';
import TransactionModal from 'components/TransactionModal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/UI/Loading';
import {
  buildChartDataFromTransaction,
  buildTransactionTableData,
  getCurrency,
  getPercentage,
  getTotalAmount,
} from 'utils/helper';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import FundsModal from './FundsModal';
import CurrencyFormat from 'react-currency-format';
import { Col, Row } from 'react-bootstrap';
import OverviewStatisticTimeLine from 'components/UI/OverviewStatisticTimeLine';
import { ReactComponent as RightArrow } from '../../../assets/icons/right-top.svg';
import { getCharts, getStatistics } from 'redux/actions/OverviewAction';
import { getTransaction } from 'redux/actions/TransactionsAction';


const TransactionsAction = () => {
  const history = useHistory();
  const onTransactionHandler = () => {
    history.push('/transactions');
  };
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center cursor px-3 borderRight text-muted">
        Export <RightArrow className="ps-1" />
        <RiseOutlined className="ps-1" />
      </div>
      <div
        onClick={onTransactionHandler}
        className="d-flex align-items-center cursor px-3"
      >
        All transactions <ArrowRightOutlined className="ps-1" />
      </div>
    </div>
  );
};

const Overview = ({ company }) => {
  // TransactionsAction top header
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultCurrency = company.defaultCurrency || 'NGN';
  const [hasData, setHasData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insideModalClick, setInsideModalClick] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const toggleModalHandler = () => setIsModalOpen(true);
  const closeHandler = () => {
    if (!insideModalClick) setIsModalOpen(false);
  };

  const {
    selectedDateRange,
    getStatistic: { data: statistic, loading },
    getChart: { data: chart }
  } = useSelector(({ overview }) => overview);

  const {
    getTransaction: { data: { meta = {} } = {}, data = [] },
  } = useSelector(({ transaction }) => transaction);

  const { transactions = [] } = data;

  // const { selectedDateRange } = useSelector(({ overview }) => overview);
  const { toggle } = useSelector(({ toggle }) => toggle);
  const [stats, setStats] = useState({ transactions: [] });
  const [selectTransaction, setSelectTransaction] = useState(null);

  useEffect(() => {
    dispatch(getStatistics());
    dispatch(getCharts());
    dispatch(getTransaction());
  }, []);

  const [overviewTotal, setOverviewTotal] = useState({
    totalDisbursed: {
      amount: 0,
      percentage: 0,
    },
    totalBalance: {
      amount: 0,
      percentage: 0,
    },
    topSpenders: {
      [defaultCurrency]: [
        {
          amount: 0,
          percentage: 0,
          firstName: '',
        },
      ],
    },
  });
  const [chartData, setChartData] = useState({
    chart: [],
    totalSpent: null,
    totalPercentage: null,
  });
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleRange = (val) => {
  };
  useEffect(() => {
    handleRange(Math.floor(Math.random() * 3) + 1);
  }, [selectedDateRange]);

  useEffect(() => {
    if (company.code) dispatch(getCompanyStats({ id: company.code }));
  }, [company]);

  useEffect(() => {
    if (chart) {
      setStats(chart);
    }
  }, [chart]);

  // useEffect(() => {
  //   setPayModal(toggle);
  // }, [toggle]);

  // const payToggleHandler = () => {
  //   dispatch(toggleAction());
  // };

  if (loading) return <Loading isPage color="#D28B28" />;

  const handleRowClick = (row) => {
    setSelectTransaction(row);
  };

  const handleRefresh = () => {
    dispatch(getCompanyStats({ id: company.code }));
  };

  const getSpendersIcons = (spenders) => {
    if (!(Array.isArray(spenders) && spenders.length)) return null;
    return spenders.map((spender, index) => (
      <span
        key={`spender-${index}`}
        className={`arrow-wrapper${index === 0 ? ' active' : ''}`}
      >
        {spender.firstName.charAt(0)}
      </span>
    ));
  };

  return (
    <div className="page-wrapper pb-5">
      <div className="w-100">
        <SubHeader handleRefresh={handleRefresh} />

        <div className='overview-statistics'>
          <Row>
            <Col md={3}>
              <OverviewStatisticTimeLine title="Employees Registered" number={statistic?.sign_ups} />
            </Col>
            <Col md={3}>
              <OverviewStatisticTimeLine title="Companies Registered" number={statistic?.companyCount} />
            </Col>
            <Col md={3}>
              <OverviewStatisticTimeLine title="Pending Review" number={statistic?.companyInReview} />
            </Col>
            <Col md={3}>
              <OverviewStatisticTimeLine title="Onboarded" number={statistic?.companyActivated} completed={true} />
            </Col>
          </Row>
        </div>

        <LineChartView
          chart={stats}
          totalSpent={chartData.totalSpent}
          currency={defaultCurrency}
          totalPercentage={chartData.totalPercentage}
          amountType='Transactions per day'
          // noDataPlaceholder="A trend of your beneficiaries spend within a period"
        />

        <div className="recent-transactions-wrapper px-1">
          <TopBar
            headerText={
              <span className="d-block position-relative mb-4 text-color">
                Recent Transactions
              </span>
            }
            isRightBar={<TransactionsAction />}
            colClass="pt-0"
          />
          <Table
            columns={columns}
            data={buildTransactionTableData(transactions, true)}
            previous={false}
            next={1}
            // onRowClick={handleRowClick}
            withTopBorder
            emptyPlaceholder="No transactions yet"
          />

          <TransactionModal
            selectTransaction={selectTransaction}
            setSelectTransaction={setSelectTransaction}
          />
        </div>
        {/* {payModal && (
            <BujetiPayModal
              title="Delete budget"
              subTitle=" Are you sure you want to delete this budget? This action can not be undone."
              onCancel={payToggleHandler}
              // onDelete={handleDelete}
            />
          )} */}
      </div>
    </div>
  );
};
export default Overview;
