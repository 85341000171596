import React, { useEffect, useState } from 'react';
import BTable from 'react-bootstrap/Table';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { usePagination, useTable } from 'react-table';
import './styles.scss';
import RenderComponent from './RenderComponent';
import cs from 'classnames';

const Table = ({
  columns,
  data,
  pagination,
  previous = false,
  currentPage,
  totalPage,
  onRowClick,
  withTopBorder,
  type,
  hasMore,
  emptyPlaceholder,
  nextPage,
  previousPage,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    pageOptions,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
  );
  const [selectedId, setSelectedId] = useState('');
  useEffect(() => {
    setPageSize(10);
  }, []);
  const displayCell = (row, cell, index) => {
    return (
      <RenderComponent
        type={type}
        column={cell.column}
        row={cell.row.original}
        value={cell.value}
        setSelectedId={setSelectedId}
        selectedId={selectedId}
        index={index}
      />
    );
  };

  if (!data.length > 0 && emptyPlaceholder)
    return (
      <div className="table-nodata">
        <span>{emptyPlaceholder}</span>
      </div>
    );
  return (
    <div className={cs({ 'table-wrapper': 1, cursor: 1, 'ps-3': 1, 'px-3': 1 })}>
      <BTable size="large" responsive {...getTableProps()}>
        <thead className={cs({ ['top-table-border']: withTopBorder })}>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <th {...column.getHeaderProps()} key={i} style={{color: '#ffffff'}}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="border-top-0" {...getTableBodyProps()} style={{color: '#ffffff', fontSize: '14px', fontWeight: '400'}}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                key={rowIndex}
                {...row.getRowProps()}
                onClick={() => (onRowClick ? onRowClick(row.original) : null)}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {displayCell(row, cell, rowIndex)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {pagination && (
        <div className="pagination-wrapper">
          <div className="pagination-actions">
            {currentPage !== 1 ? (
              <p className="me-3" onClick={() => previousPage()}>
                <ArrowLeftOutlined className="pe-2" />
                <span>Previous page</span>
              </p>
            ) : null}
            {currentPage !== totalPage && (
              <p onClick={() => nextPage()}>
                <span>Next page</span>
                <ArrowRightOutlined className="ps-2" />
              </p>
            )}
          </div>
          <div className="pagination-number">
            <p>
              Page {currentPage} of {totalPage}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default Table;
