import React, { useEffect, useState } from 'react';
import VitrineSidebar from 'components/Auth/LeftVitrine';
import signImage from 'assets/images/sign-image.png';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import '../style.less';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { useHistory } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'redux/actions/AuthAction';
import { toast } from 'react-toastify';
import { validatePassword } from 'utils/helper';
import { ReactComponent as Bujeti } from '../../../assets/icons/bujeti.svg';

const Login = () => {
  const leftImage = (
    <>
      <img src={signImage} className="login-vitrine-img" />
    </>
  );
  const history = useHistory();

  const {
    login: { error = {} },
  } = useSelector(({ auth }) => auth);
  useEffect(() => {
    if (error) {
      if (error === 'Please verify your email/phone number') {
        history.push({
          pathname: '/verification',
          state: { authData: authData.email },
        });
      }
    }
  }, [error]);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [authData, setAuthData] = React.useState({
    email: '',
    password: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!authData.email) return toast('Place enter email', { type: 'error' });
    if (!validatePassword(authData.password))
      return toast('Place enter strong password', { type: 'error' });

    dispatch(login(authData));
    setValidated(true);
  };

  return (
    <div className="page-wrapper">
      <div className="page-content with-vit">
        <AuthFormWrapper>
          <Bujeti className="mb-4" />
          <AuthFormHeader title="Welcome to Mirador!" />
          <Form>
            <Row className="mb-3">
              <CustomInput
                error="enter valid email"
                placeholder="Enter email"
                type="email"
                name="email"
                onChange={handleChange}
                value={authData.email}
                required
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                error="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                placeholder="Enter password"
                type="password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                value={authData.password}
                onChange={handleChange}
                name="password"
                required
              />
            </Row>
            <CustomButton type="submit" onClick={handleSubmit}>
              Log in
            </CustomButton>
          </Form>

          {/* <h4 className="secondary-action">
            <span className="font-weight-normal"> Forget your password? </span>
            <Link to="/reset-request"> Request reset </Link>
          </h4> */}
        </AuthFormWrapper>
      </div>
    </div>
  );
};

export default Login;
