export const StatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'invited',
        label: 'Invited',
        isSelected: false,
      },
      {
        value: 'inactive',
        label: 'Disabled',
        isSelected: false,
      },
    ],
  },
];

export const RolesType = [
  {
    title: 'Roles',
    list: [
      {
        value: 'admin',
        label: 'Admin',
        isSelected: false,
      },
      {
        value: 'manager',
        label: 'Manager',
        isSelected: false,
      },
      {
        value: 'employee',
        label: 'Employee',
        isSelected: false,
      },
    ],
  },
];

export const CurrencyType = [
  {
    title: 'Currency',
    list: [
      {
        value: 'USD',
        label: 'US Dollar ($)',
        isSelected: false,
      },
      {
        value: 'NGN',
        label: 'Naira (₦)',
        isSelected: false,
      },
    ],
  },
];

export const CardType = [
  {
    title: 'Type',
    list: [
      {
        value: 'Virtual',
        label: 'Virtual',
        isSelected: false,
      },
      {
        value: 'Physical',
        label: 'Physical',
        isSelected: false,
      },
    ],
  },
];
export const PaymentMethod = [
  {
    title: 'Payment Method',
    list: [
      {
        value: 'virtual',
        label: 'Virtual Card',
        isSelected: false,
      },
      {
        value: 'physical',
        label: 'Physical Card',
        isSelected: false,
      },
      {
        value: 'bank',
        label: 'Bank Transfer',
        isSelected: false,
      },
    ],
  },
];

export const CardStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'Processing',
        label: 'Processing',
        isSelected: false,
      },
      {
        value: 'Active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'inactive',
        label: 'Disabled',
        isSelected: false,
      },
      {
        value: 'Frozen',
        label: 'Frozen',
        isSelected: false,
      },
    ],
  },
];

export const TransactionStatus = [
  {
    title: 'Status',
    list: [
      {
        value: 'processing',
        label: 'Processing',
        isSelected: false,
      },
      {
        value: 'success',
        label: 'Success',
        isSelected: false,
      },
      {
        value: 'failed',
        label: 'Failed',
        isSelected: false,
      }
    ],
  },
];

export const ReimbursementStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'pending',
        label: 'Pending',
        isSelected: false,
      },
      {
        value: 'approved',
        label: 'Approved',
        isSelected: false,
      },
      {
        value: 'paid',
        label: 'Paid',
        isSelected: false,
      },
      {
        value: 'deleted',
        label: 'Deleted',
        isSelected: false,
      },
    ],
  },
];

export const BudgetsStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'inactive',
        label: 'Inactive',
        isSelected: false,
      },
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'pause',
        label: 'Paused',
        isSelected: false,
      },
    ],
  },
];
