import Cookies from 'js-cookie';

export const isLoggedIn = () => {
  const token = Cookies.get('mrdr-token');
  return !!token;
};

export const getUserLandingPage = (user) => {
  return '/';
};
