import BadgeType from 'components/UI/Table/BadgeType';
import StringType from 'components/UI/Table/StringType';
import React from 'react';
import { cellType } from 'utils/helper';
const RenderComponent = ({ column, row, value, type, selectedId, setSelectedId,index }) => {
  switch (column?.type) {
    case cellType.STRING:
      return (
        <StringType
          value={value}
          withIcon={column.withIcon}
          withNumber={column.withNumber}
          withImg={column.withImg}
          link={column.link}
          type={type}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          index={index}
        />
      );
    case cellType.STRING_BOLD:
      return (
        <StringType
          value={value}
          withIcon={column.withIcon}
          withNumber={column.withNumber}
          link={column.link}
          fontBold
          type={type}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          index={index}
        />
      );
    case cellType.BADGE:
      return <BadgeType value={value} />;
    default:
      return <span>Enter Valid type</span>;
  }
};
export default RenderComponent;
