import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import './styles.scss';
import { awsUploadNew } from 'utils/FileUploadUtil';
import { ReactComponent as CloudArrowUp } from 'assets/icons/CloudArrowUp.svg';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';
import { useDropzone } from 'react-dropzone';
import Loading from '../Loading';
import { createAssets, uploadFiles } from 'redux/actions/AssetsAction';

const FileUpload = ({
  label,
  wrapperClass,
  onChange,
  setUploadingFile,
  uploadingFile,
  supportType = 'Supported file types: jpeg, png, pdf. Max file size: 5mb',
  cloud = true,
  acceptedFile = {
    'image/jpeg': ['.jpeg', '.png', '.pdf'],
  },
  externalUpload,
  checkIsDisabled,
  removeFile,
  setRemoveFile,
  uploadedFile,
  name = 'file',
  companyCode,
  multiple = false,
}) => {
  const dispatch = useDispatch();

  const {
    uploadFiles: { data = [], success: assetSuccess, loading },
  } = useSelector(({ assets }) => assets);

  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadedDiv, setUploadedDiv] = useState(false);
  const [uploads, setUploads] = useState([]);

  useEffect(() => {
    if (assetSuccess) {
      setUploads([...uploads, ...data]);
    }
  }, [assetSuccess]);

  useMemo(() => {
    if (multiple) {
      if (onChange) {
        onChange(uploads);
      }
    }
  }, [uploads]);

  const handleSelect = async (val) => {
    setUploading(false);
    setFileName(val.fileName);
    setUploadingFile(false);
    if (onChange) {
      onChange(val);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: uploadingFile,
    maxFiles: multiple ? 10 : 1,
    accept: acceptedFile,
    maxSize: 5 * 1024 * 1024,
  });

  useEffect(() => {
    if (multiple) {
      setUploading(loading);
      setUploadedDiv(loading);
      setUploadingFile && setUploadingFile(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (acceptedFiles.length > 0 && cloud && multiple) {
      dispatch(uploadFiles(acceptedFiles));
      return;
    }

    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];

      const handleFileChange = async (file) => {
        if (cloud) {
          setUploading(true);
          setUploadedDiv(true);
          setUploadingFile(true);

          const payload = {
            fileName: file.name,
            fileMime: file.type,
            company: companyCode,
          };

          const upload = await awsUploadNew(
            payload,
            file,
            setProgress,
            setUploading,
            handleSelect,
          );

          if (upload === 'error') {
            setUploadingFile(false);
            handleRemove();
            setFileName('');
            // throw new Error(upload);
          }
          // else {
          //   getAssetCode()
          // }
        } else {
          setRemoveFile(false);
          setFileName(file.name);
          onChange({ file, fileName: file.name });
          setUploadedDiv(true);
        }
      };

      handleFileChange(file);
    }
  }, [acceptedFiles]);

  const handleRemove = () => {
    setFileName('');
    setProgress(0);
    onChange({
      fileName: '',
      fileType: '',
      key: '',
      originalFilename: '',
      signedUrl: '',
      url: '',
      assetCode: '',
    });
    setUploadedDiv(false);
  };

  const handleMultipleRemove = (code) => {
    let newArray = [...uploads];
    newArray = newArray.filter((file) => file.assetCode !== code);
    setUploads(newArray);
  };

  useEffect(() => {
    if (uploadedFile) setUploadedDiv(true);
  }, [uploadedFile]);

  useEffect(() => {
    if (removeFile) handleRemove();
  }, [removeFile]);

  const uploadedName = uploadedFile ? 'Change file' : undefined;
  return (
    <div
      className={cs('fileUpload groupWrapper cursor', { [wrapperClass]: wrapperClass })}
    >
      <div>
        <label className="uploadFileLabel">{label}</label>
        {!uploadedDiv && (
          <div>
            <div
              className="uploadFileBorder w-100"
              onClick={uploadingFile ? checkIsDisabled : null}
            >
              <div {...getRootProps({ className: 'dropzone w-100' })}>
                <input {...getInputProps()} id={name} name={name} />
                <div className="uploadFileIcon">
                  <CloudArrowUp />
                </div>
                <div className="uploadFileTextBorder">
                  <span className="uploadFileText1">
                    Drop files here to attach, or
                    <span style={{ color: '#D28B28' }}>&nbsp;browse</span>
                  </span>
                  <span className="uploadFileText2">{supportType}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div className="loader-line" style={{ width: `${progress}%` }} /> */}
      {uploadedDiv && (
        <div className="fileUploaded">
          {(uploading || externalUpload) && <Loading color="#D28B28" size={20} />}
          <span className="uploadedFileText">{fileName || uploadedName}</span>
          {!(uploading || externalUpload) && <Trash onClick={handleRemove} />}
        </div>
      )}

      {!!uploads.length &&
        uploads.map((item, index) => (
          <div className="fileUploaded" key={index}>
            <span className="uploadedFileText text-truncate">{item.fileName}</span>
            <Trash onClick={() => handleMultipleRemove(item.assetCode)} />
          </div>
        ))}
    </div>
  );
};
export default FileUpload;
