import {
  GET_KYBREQUESTS_REQUEST,
  GET_SINGLE_KYBREQUESTS_REQUEST,
  APPROVE_DISAPPROVE_KYB_REQUEST,
  SUBMIT_DOCUMENTS_REQUEST,
  GET_REQUIRED_DOCUMENTS_REQUEST,
  GET_SINGLE_COMPLIENCE_REQUEST,
  UPDATE_KYBREQUESTS_REQUEST,
} from '../reducers/KybRequestsReducer';

export const getKybRequests = (payload) => ({
  type: GET_KYBREQUESTS_REQUEST,
  payload,
});

export const getSingleKybRequest = (payload) => ({
  type: GET_SINGLE_KYBREQUESTS_REQUEST,
  payload,
});

export const approveDisapproveKYBRequests = (payload) => ({
  type: APPROVE_DISAPPROVE_KYB_REQUEST,
  payload,
});

export const submitRequiredDocuments = (payload) => ({
  type: SUBMIT_DOCUMENTS_REQUEST,
  payload,
});

export const getRequiredDocuments = (payload) => ({
  type: GET_REQUIRED_DOCUMENTS_REQUEST,
  payload,
});

export const getSingleComplience = (payload) => ({
  type: GET_SINGLE_COMPLIENCE_REQUEST,
  payload,
});

export const updateKybRequest = (payload) => ({
  type: UPDATE_KYBREQUESTS_REQUEST,
  payload,
});
