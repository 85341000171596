import React from 'react';
import { Badge } from 'react-bootstrap';
import './styles.scss';
import { getColor } from 'utils/helper';

const BadgeType = ({ value: status }) => {
  return (
    <span
      style={{ backgroundColor: '#242628', color: `${status.color}` }}
      className="badge-wrapper"
    >
      <Badge className="text-uppercase bage-inherit text-dark">{status.value}</Badge>
    </span>
  );
};
export default BadgeType;
