import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Input } from 'antd';
import {
  SearchOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import FilterList from './FilterList';
import AccordionCard from '../UI/AccordionCard';
import CustomButton from '../UI/CustomButton';
import CustomSelect from '../UI/CustomSelect';
import './styles.less';
import CustomDate from '../UI/CustomDate';
import { getCompanies } from 'redux/actions/CompaniesAction';
import { useDebounce } from 'hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';

const FilterModal = ({
  filterData = [],
  inputPlaceholder = 'Search ',
  handleFilterSelect,
  handleFilterApply,
  handleToggleFilterPopover,
  withDate,
  withOutSearch = false,
}) => {
  const [activeKey, setActiveKey] = useState('0');
  const [amount, setAmount] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [companyName, setCompanyName] = useState({
    label: 'select a company',
    value: '',
    code: '',
  });
  const [status, setStatus] = useState('');
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const [date, setDate] = useState({
    from: '',
    to: '',
  });
  const dispatch = useDispatch();

  const {
    getCompany: { data: { companies = [] } = {}, loading, success },
  } = useSelector(({ companies }) => companies);

  const mappedComp = companies?.map((item) => item.code);

  const companiesList = useMemo(() => {
    return companies?.map((item) => ({
      label: item.name,
      value: item.name,
      code: item.code,
    }));
  }, [success, mappedComp]);

  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const handleChange = (value, checked, type, title) => {
    if (type === 'Checkbox') {
      const updateData = filterData.map((list) => {
        if (list.title === title) {
          const fromIndex = list.list.map((object) => object.value).indexOf(value);
          const toIndex = 0;
          const element = list.list.splice(fromIndex, 1)[0];
          list.list.splice(toIndex, 0, element);
        }

        if (list.type === 'slider') return { ...list };
        return {
          ...list,
          list: list.list?.map((list) => {
            if (list.value === value) {
              return {
                ...list,
                isSelected: checked,
              };
            } else {
              return { ...list };
            }
          }),
        };
      });
      //
      handleFilterSelect(updateData, { value, checked });
    }
    if (type === 'Slider') {
      const setFilter = filterData.map((list) => {
        if (list.type === 'slider') {
          return {
            ...list,
            value: value,
          };
        }
        return { ...list };
      });
      handleFilterSelect(setFilter, {});
    }
  };

  const handleApply = () => {
    const query = {};
    if (searchVal) {
      query.search = searchVal;
    }
    if (status) {
      query.status = status;
    }
    if (amount) {
      query.amount = parseInt(amount, 10) * 100;
    }
    if (companyName?.code) query.company = companyName.code;

    if (withDate) {
      if (date.from) query.from = date.from;
      if (date.to) query.to = date.to;
    }

    handleFilterApply(query);
    handleToggleFilterPopover();
  };

  const handleDateChange = ({ target: { value, name } }) => {
    setDate({ ...date, [name]: value });
  };

  const handleSelectChange = (val) => {
    // console.log(val);
    setCompanyName(val);
  };

  return (
    <div className="actions-dialog pb-3 set-height-filter">
      <h3 className="filter-title">Filter</h3>
      {!withOutSearch && (
        <div className="search-wrapper pb-2">
          Description or transaction code
          <Input
            placeholder={inputPlaceholder}
            prefix={<SearchOutlined className="searchIcon" />}
            value={searchVal}
            onChange={({ target: { value } }) => setSearchVal(value)}
          />
        </div>
      )}
      <div className="search-wrapper pb-2">
        Amount
        <Input
          placeholder={'100,000'}
          prefix={<NumberOutlined className="searchIcon" />}
          value={amount}
          onChange={({ target: { value } }) => setAmount(value)}
        />
      </div>
      <div className="search-wrapper pb-2">
        Status
        <Input
          placeholder={'success, declined'}
          prefix={<QuestionCircleOutlined className="searchIcon" />}
          value={status}
          onChange={({ target: { value } }) => setStatus(value)}
        />
      </div>
      <div className="search-wrapper pb-2">
        Company name
        <CustomSelect
          label="Select a Business"
          name="company"
          placeholder="Select a Business"
          onInputChange={handleSelectChange}
          onChange={(val) => setCompanyName(val)}
          value={companyName.value}
          options={companiesList}
          isLoading={loading && !debouncedValue}
          isDisabled={loading && !debouncedValue}
        />
      </div>
      <div className="filter-action-list pb-0">
        <Row className="mb-3">
          <Col md={6}>
            <CustomDate label="From" name="from" onChange={handleDateChange} />
          </Col>
          <Col md={6}>
            <CustomDate label="To" name="to" onChange={handleDateChange} />
          </Col>
        </Row>
      </div>

      <div className="px-3 mt-2">
        <CustomButton className="apply-btn" onClick={handleApply}>
          Apply filter
        </CustomButton>
      </div>
    </div>
  );
};
export default FilterModal;
