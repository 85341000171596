import React, { useState } from 'react';
import cs from 'classnames';
import './styles.scss';
import { Popover } from 'react-tiny-popover';
import { useHistory } from 'react-router-dom';

const StringType = ({
  value,
  fontBold,
  withIcon,
  onClick = null,
  withNumber,
  withImg,
  link,
  type,
  setSelectedId,
  selectedId,
  index,
}) => {
  const history = useHistory();
  if (!value) return <div />;
  const handleClick = (e) => {
    if (index === selectedId) return;
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      return onClick();
    }
    if (link) {
      e.preventDefault();
      e.stopPropagation();
      history.push(value.link);
    }
  };

  const handleTogglePopover = (e) => {
    if (value.value !== '(Add to a budget)') {
      e.preventDefault();
      e.stopPropagation();
      if (selectedId === index) return setSelectedId('');
      setSelectedId(index);
      // return setIsPopoverOpen(selectedId);
    }
  };

  const Actions = ({ list }) => {
    return (
      <div className="actions-dialog">
        {list.map((l, i) => {
          return (
            <div
              key={i}
              className="actionLink"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(l.link);
              }}
            >
              {l.name}
            </div>
          );
        })}
      </div>
    );
  };

  if (withIcon || withImg) {
    const iconRender =
      type === 'beneficiaries'
        ? +value.number >= 1 || value.value === '(Add to a budget)'
        : !!value.icon;
    return (
      <div className={cs({ ['fw-light-bold']: fontBold }, 'align-items-center d-flex')}>
        {withImg && (
          <span className="pe-2 table-card" onClick={handleClick}>
            {value.img}
          </span>
        )}
        {value.value ? <span onClick={handleClick}>{value.value}</span> : ''}
        <Popover
          isOpen={index === selectedId}
          align="end"
          toggleHandler={handleTogglePopover}
          positions={['bottom']} // preferred positions by priority
          content={<Actions list={value?.numberList} />}
          onClickOutside={handleTogglePopover}
        >
          <div onClick={handleTogglePopover} className="cursor d-flex align-items-center">
            {withNumber && <span className="ps-2 silver-color">{value.number}</span>}
            {iconRender && (
              <span className="ps-1 d-flex align-items-center silver-color">
                {index === selectedId ? value.downIcon : value.icon}
              </span>
            )}
          </div>
        </Popover>
      </div>
    );
  }
  return <span className={cs({ ['fw-light-bold']: fontBold })}>{value}</span>;
};
export default StringType;
