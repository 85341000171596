export const GET_KYBREQUESTS_REQUEST = 'GET_KYBREQUESTS_REQUEST';
export const GET_KYBREQUESTS_SUCCESS = 'GET_KYBREQUESTS_SUCCESS';
export const GET_KYBREQUESTS_ERROR = 'GET_KYBREQUESTS_ERROR';

export const GET_SINGLE_KYBREQUESTS_REQUEST = 'GET_SINGLE_KYBREQUESTS_REQUEST';
export const GET_SINGLE_KYBREQUESTS_SUCCESS = 'GET_SINGLE_KYBREQUESTS_SUCCESS';
export const GET_SINGLE_KYBREQUESTS_ERROR = 'GET_SINGLE_KYBREQUESTS_ERROR';

export const APPROVE_DISAPPROVE_KYB_REQUEST = 'APPROVE_DISAPPROVE_KYB_REQUEST';
export const APPROVE_DISAPPROVE_KYB_SUCCESS = 'APPROVE_DISAPPROVE_KYB_SUCCESS';
export const APPROVE_DISAPPROVE_KYB_ERROR = 'APPROVE_DISAPPROVE_KYB_ERROR';

export const GET_REQUIRED_DOCUMENTS_REQUEST = 'GET_REQUIRED_DOCUMENTS_REQUEST';
export const GET_REQUIRED_DOCUMENTS_SUCCESS = 'GET_REQUIRED_DOCUMENTS_SUCCESS';
export const GET_REQUIRED_DOCUMENTS_ERROR = 'GET_REQUIRED_DOCUMENTS_ERROR';

export const SUBMIT_DOCUMENTS_REQUEST = 'SUBMIT_DOCUMENTS_REQUEST';
export const SUBMIT_DOCUMENTS_SUCCESS = 'SUBMIT_DOCUMENTS_SUCCESS';
export const SUBMIT_DOCUMENTS_ERROR = 'SUBMIT_DOCUMENTS_ERROR';

export const GET_SINGLE_COMPLIENCE_REQUEST = 'GET_SINGLE_COMPLIENCE_REQUEST';
export const GET_SINGLE_COMPLIENCE_SUCCESS = 'GET_SINGLE_COMPLIENCE_SUCCESS';
export const GET_SINGLE_COMPLIENCE_ERROR = 'GET_SINGLE_COMPLIENCE_ERROR';

export const UPDATE_KYBREQUESTS_REQUEST = 'UPDATE_KYBREQUESTS_REQUEST';
export const UPDATE_KYBREQUESTS_SUCCESS = 'UPDATE_KYBREQUESTS_SUCCESS';
export const UPDATE_KYBREQUESTS_ERROR = 'UPDATE_KYBREQUESTS_ERROR';

export const RESET_BLOCK_KYBREQUESTS = 'RESET_BLOCK_KYBREQUESTS';

export const RESET_FLAGS_KYBREQUESTS = 'RESET_FLAGS_KYBREQUESTS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getKybRequest: { ...block },
  getSingleKybRequest: { ...block },
  approveDisapproveKYBRequest: { ...block },
  getRequiredDocuments: { ...block },
  submitDocuments: { ...block },
  getSingleComplience: { ...block },
  updateKybRequest: { ...block },
};

export const KybRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KYBREQUESTS_REQUEST:
      return { ...state, getKybRequest: { ...state.getKybRequest, loading: true } };
    case GET_KYBREQUESTS_SUCCESS:
      return {
        ...state,
        getKybRequest: {
          ...state.getKybRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_KYBREQUESTS_ERROR:
      return {
        ...state,
        getKybRequest: {
          ...state.getKybRequest,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_KYBREQUESTS_REQUEST:
      return { ...state, updateKybRequest: { ...state.updateKybRequest, loading: true } };
    case UPDATE_KYBREQUESTS_SUCCESS:
      return {
        ...state,
        updateKybRequest: {
          ...state.updateKybRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_KYBREQUESTS_ERROR:
      return {
        ...state,
        updateKybRequest: {
          ...state.updateKybRequest,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_KYBREQUESTS_REQUEST:
      return {
        ...state,
        getSingleKybRequest: { ...state.getSingleKybRequest, loading: true },
      };
    case GET_SINGLE_KYBREQUESTS_SUCCESS:
      return {
        ...state,
        getSingleKybRequest: {
          ...state.getSingleKybRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_KYBREQUESTS_ERROR:
      return {
        ...state,
        getSingleKybRequest: {
          ...state.getSingleKybRequest,
          loading: false,
          error: action.error,
        },
      };

    case APPROVE_DISAPPROVE_KYB_REQUEST:
      return {
        ...state,
        approveDisapproveKYBRequest: {
          ...state.approveDisapproveKYBRequest,
          loading: true,
        },
      };
    case APPROVE_DISAPPROVE_KYB_SUCCESS:
      return {
        ...state,
        approveDisapproveKYBRequest: {
          ...state.approveDisapproveKYBRequest,
          loading: false,
          success: true,
          error: '',
        },
        user: action.data,
      };
    case APPROVE_DISAPPROVE_KYB_ERROR:
      return {
        ...state,
        approveDisapproveKYBRequest: {
          ...state.approveDisapproveKYBRequest,
          loading: false,
          error: action.error,
        },
      };

    case GET_REQUIRED_DOCUMENTS_REQUEST:
      return {
        ...state,
        getRequiredDocuments: { ...state.getRequiredDocuments, loading: true },
      };
    case GET_REQUIRED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getRequiredDocuments: {
          ...state.getRequiredDocuments,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_REQUIRED_DOCUMENTS_ERROR:
      return {
        ...state,
        getRequiredDocuments: {
          ...state.getRequiredDocuments,
          loading: false,
          error: action.error,
        },
      };

    case SUBMIT_DOCUMENTS_REQUEST:
      return { ...state, submitDocuments: { ...state.submitDocuments, loading: true } };
    case SUBMIT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        submitDocuments: {
          ...state.submitDocuments,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SUBMIT_DOCUMENTS_ERROR:
      return {
        ...state,
        submitDocuments: {
          ...state.submitDocuments,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_COMPLIENCE_REQUEST:
      return {
        ...state,
        getSingleComplience: { ...state.getSingleComplience, loading: true },
      };
    case GET_SINGLE_COMPLIENCE_SUCCESS:
      return {
        ...state,
        getSingleComplience: {
          ...state.getSingleComplience,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_COMPLIENCE_ERROR:
      return {
        ...state,
        getSingleComplience: {
          ...state.getSingleComplience,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_KYBREQUESTS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_KYBREQUESTS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
